
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getProductsBySeller,
  getSellersOfProduct,
  getCategoriesBySeller
} from '@/services/seller.service'
import { Product } from '@/interfaces/product.interface'
import { productDate } from '@/helpers/date'
import { ErrorMessages } from '@/helpers/constants'
import { State } from 'vuex-class'
import { getMyData } from '@/services/user.service'

@Component({
  components: {
    SellerHeader,
  },
})
export default class Products extends Vue {
  //@State(({ sellerState }) => sellerState.eventSelected) eventId!: number;
  eventId!: number = localStorage.getItem('selectedEvent')
  public products: Product[] = []
  public productSellers = []

  public viewSellerInProducts = 0
  public productsAutocomplete = {}
  public productsFiltered: Product[] = []
  public filter = ''
  public page = 1
  public totalPages = 1
  public filterByID = ''
  public categoryId = ''
  public categories = []

  public filtersSelected = 'all'
  public filtersSelects = [
    {
      text: 'Todos',
      value: 'all',
    },
    {
      text: 'Productos vendidos',
      value: 'sold',
    },
    {
      text: 'Productos disponibles',
      value: 'available',
    },
  ]

  public skeletonCount = [0, 1, 2, 3]
  public loading = false

  public total_products_sold_type = null

  setTicketsQuotaColor(constrain: boolean) {
    if (constrain) {
      return 'text-color-red'
    }
    return 'text-color-blue'
  }
  async getProducts() {
    try {
      this.loading = true
      const eventId = this.eventId != null ? this.eventId : localStorage.getItem('selectedEvent');
      const { meta, data, configs } = await getProductsBySeller(
        eventId,
        this.page,
        50,
        this.categoryId,
      )
      this.totalPages = Math.ceil(meta.total / 50)
      this.products = data

      this.total_products_sold_type = configs
        ? configs.total_products_sold_type
        : false

      this.productsAutocomplete = this.products.map(function(x) {
        return {
          value: x.event.id,
          text: x.event.name,
        }
        // return [x.event.name];
      })

      this.productsFiltered = this.products
      this.filterByStatus(this.filtersSelected)
      this.loading = false
    } catch (error) {
      console.log(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  async getCategories() {
    this.loading = true
    try {
      const { data } = await getCategoriesBySeller(this.eventId, true)
      this.categories = data
      this.categories.unshift({
        name: 'Todos los productos',
        id: -1,
      })
      this.categoryId = this.categories[0].id
      this.loading = false
    } catch (error) {
      this.$toast.error(ErrorMessages.COMMON)
    }
  }



  dateToHours(isoDate) {
    return productDate(isoDate)
  }

  goToProductDetail(product: Product) {
    console.log('Producto recibido:', product);
    if (!product.canGenerateTicket) {
      this.productDisabled = true;
      this.productDisabledMessage = product.ticketMessage || 'NO DISPONIBLE';
      this.$bvModal.show('modal-warning');
      return;
    }

    this.$router.push({
      name: 'Generar Link',
      params: { productId: product.uuid },
    });
  }
  



  mounted() {
    this.getCategories()
    this.getProducts()
    this.getMyData()
  }

  async getMyData() {
    try {
      const { data } = await getMyData()
      this.viewSellerInProducts = data.business_admin
    } catch (e) {
      console.error(e)
    }
  }

  async getSellersList(id) {
    try {
      const loader = Vue.$loading.show()

      const { data } = await getSellersOfProduct(id)
      this.productSellers = data
      loader.hide()
    } catch (e) {
      console.error(e)
    }
  }

  searchProduct() {
    this.productsFiltered = this.products.filter(
      product =>
        product.event.id.toString().includes(this.filterByID.toString()) &&
        product.name.toLowerCase().includes(this.filter.toLowerCase())
    )
  }
  filterByStatus(filterStatus: string) {
    this.filtersSelected = filterStatus
    this.productsFiltered = this.products.filter(product => {
      if (filterStatus === 'all') {
        return (this.productsFiltered = this.products)
      }

      if (filterStatus === 'sold') {
        if (
          product.total_tickets_active >= product.max_quote ||
          product.sell_simple > 0
        ) {
          return true
        }
      } else {
        if (product.total_tickets_active < product.max_quote) {
          return true
        }
      }
    })
  }
  ocupate(totalTicket: number | null) {
    return totalTicket || 0
  }

  // get productsFilter() {

  //   return this.products.filter(product => {
  //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //     return product.name.indexOf(this.filter) > -1;
  //   });

  // }

  @Watch('page', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPageChange(nextPage: number, _currentPage: unknown) {
    this.page = nextPage
    this.getProducts()
  }
}
